import React, { useEffect } from "react";

export const Basic = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "29.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
        
    }
    // Clean up PayPal buttons on component unmount
    return () => {
      //document.getElementById("paypal-container-9ZUUPLV7BLJDC").innerHTML = "";
    };
  }, [paypalLoaded]);

  return (
    <div>
      <div className="package-card">
  <h3 className="package-title"> Paquete Básico 🌱 </h3>

  <p className="package-description">
    Disfruta del <strong>Cañón del Río La Vieja</strong> con lo necesario para una experiencia inolvidable. 
    Ideal si buscas una aventura natural sin complicaciones.
  </p>

  <hr></hr> <br></br>
<ul className="package-benefits">
  {[
    {
      label: "Guía local:",
      text: "Seguridad y conocimiento del terreno.",
    },
    {
      label: "Acceso al sendero:",
      text: "Caminata guiada hasta la Cascada Zafiro.",
    },
    {
      label: "Equipo incluido:",
      text: "Casco de seguridad y orientación.",
    },
    {
      label: "Facilidades:",
      text: "Vestidores, baños y estacionamiento.",
    },
  ].map((item, index) => (
    <li key={index}>
      <div>
       
      🔘<strong className="text-white">{item.label}</strong>&nbsp;
        <br></br>
        <span className="text-gray-200">{item.text}</span>
      </div>
    </li>
  ))}
</ul>


  <div className="package-price">
    <span className="price-label"> Precio:</span>
    <span className="price-amount">$29 / ₡14,990</span>
  </div>

  <p className="package-availability">
     Salidas: 8:00, 9:00 o 10:00 a. m. <br />
     Requiere buena condición física.
  </p>

  <button className="package-button">📩 Reservar Ahora</button>
</div>

    </div>
  );
};

export default Basic;
