export const Footer = () => {
  return (
    <footer className="bg-green-800 text-white py-12 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
        
        {/* Contacto directo */}
        <div>
          <h1 className="text-xl font-bold mb-4">📞 ¿Dudas o reservas?</h1>
          <p className="text-sm mb-3">¡Estamos para ayudarte!</p>
          <ul className="space-y-2 text-sm mb-2 mt-1">
            <li>
              💬 WhatsApp:{" "}
              <a
                href="https://wa.me/50662332535"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-300 underline hover:text-green-100"
              >
                +506 6233 2535
              </a>
            </li>
            <br></br>
            <li>
              📩 Correo:{" "}
              <a
                href="mailto:ciudadesmeraldacr@gmail.com"
                className="text-green-300 underline hover:text-green-100 mb-10"
              >
                ciudadesmeraldacr@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <br></br>

        {/* Ubicación */}
        <div>
          <hr></hr>
          <br></br>
          <h1 className="text-xl font-bold mb-4 mt-10">📍 ¿Dónde estamos?</h1>
          <p className="text-sm">
            Sucre, San Carlos – a minutos de Ciudad Quesada
          </p>
          <br></br>
          <iframe
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.8576553286803!2d-84.43576159999999!3d10.2730511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa067f4b8714307%3A0x964bf886a8c78704!2sLa%20Vieja%20Adventures!5e0!3m2!1ses-419!2sus!4v1741318576429!5m2!1ses-419!2sus"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            className="rounded-md mt-3"
          ></iframe>
          <a
            href="https://goo.gl/maps/ahcSaFXtoXhcX6uu6"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block mt-2 text-green-300 underline hover:text-green-100 text-sm"
          >
            Ver en Google Maps
          </a>
        </div>

        {/* Redes sociales */}
        <div>
          <h1 className="text-xl font-bold mb-4">🌐 Conéctate con la aventura</h1>
          <ul className="space-y-2 text-sm">
            <li>
              📸 Instagram:{" "}
              <a
                href="https://www.instagram.com/laviejaadventures/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-300 underline hover:text-green-100"
              >
                @laviejaadventures
              </a>
            </li>
            <li>
              👍 Facebook:{" "}
              <a
                href="https://www.facebook.com/@laviejaadventures"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-300 underline hover:text-green-100"
              >
                La Vieja Adventures
              </a>
            </li>
          </ul>
          <p className="mt-4 text-sm">
            Síguenos para ver fotos reales de los recorridos, actualizaciones y más sorpresas.
          </p>
          <br></br>
        </div>
      </div>

      {/* Pie de página final */}
      <div className="mt-10 text-center text-xs text-green-200 border-t border-green-700 pt-4">
        © {new Date().getFullYear()} Ciudad Esmeralda  <br></br>· Naturaleza, aventura y pura vida.
      </div>
    </footer>
  );
};

export default Footer;
