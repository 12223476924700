import { FaWhatsapp } from "react-icons/fa";
import { FaComments  } from 'react-icons/fa';

export const Header = () => {
  const phoneNumber = "+50662332535";
  const message = encodeURIComponent(
    "¡Hola! Estoy interesado en el tour al Cañón del Río La Vieja."
  );

  return (
    <header
      className="hero-header"
      style={{ backgroundImage: 'url("/IMG_3295.JPG")' }}
    >
      <div className="hero-overlay">
        <div className="hero-content">
          <h1>CIUDAD ESMERALDA</h1>
          <p className="highlight">
            Vive una aventura única, segura y llena de emociones
            <br />
            en el corazón de Costa Rica.
          </p>
          <video autoPlay loop muted width="100%" height="auto">
            <source src="/adventure2.mp4" type="video/mp4" />
            Tu navegador no soporta el video.
          </video>
        </div>
      </div>

      <a
        href={`https://wa.me/${phoneNumber}?text=${message}`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
        aria-label="Contáctanos por WhatsApp"
      >
        <FaWhatsapp className="icon" />
      </a>
<br></br>
      <a
        href="https://chatgpt.com/g/g-67e27e34edac81918f303c6bb34d68fa-ciudad-esmeralda"
        target="_blank"
        rel="noopener noreferrer"
        className="gpt-button icon2"
        aria-label="Abrir Ciudad Esmeralda GPT"
      >
        <FaComments  className="icon text-xl" />
      
      </a>
    </header>
  );
};

export default Header;