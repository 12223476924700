import React, { useEffect } from "react";

export const Private = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "59.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
    }

    // Clean up PayPal buttons on component unmount
    return () => {
      //document.getElementById("paypal-container-ZFKB85D82BW26").innerHTML = "";
    };
  }, [paypalLoaded]);
  return (
    <div>
      <div className="package-card">
          <h3 className="package-title"> Paquete Privado 🌿 </h3>
          
          <p className="package-description">
            Vive la magia del <strong>Cañón del Río La Vieja</strong> con un recorrido 100% personalizado. 
            Perfecto para quienes buscan <strong>privacidad, flexibilidad y confort</strong> en un entorno natural impresionante.
          </p>

          <hr></hr> <br></br>
<ul className="package-benefits">
  {[
    {
      label: "Guía exclusivo:",
      text: "Atención personalizada.",
    },
    {
      label: "Ruta flexible:",
      text: "Adapta el recorrido a tu ritmo.",
    },
    {
      label: "Almuerzo típico incluido:",
      text: "Disfruta un casado delicioso.",
    },
    {
      label: "Acceso VIP:",
      text: "Uso de todas las instalaciones y acceso extendido",
    },
  ].map((item, index) => (
    <li key={index}>
      <div>
        
      🔘<strong className="text-white">
          {item.label}
        </strong>&nbsp;
        <br></br>
        <span className="text-gray-200">{item.text}</span>
      </div>
    </li>
  ))}
</ul>

          <div className="package-price">
            <span className="price-label"> Precio:</span>  
            <span className="price-amount">$59 / ₡29,990</span>
          </div>

          <p className="package-availability">
             <strong>Disponible solo entre semana.</strong> <br />
             Reserva con anticipación para asegurar tu cupo.
          </p>

          <button className="package-button"> Reservar Ahora</button>
      </div>
    </div>
  );
};

export default Private;
