import React, { useEffect } from "react";

export const Daily = ({ paypalLoaded }) => {
  useEffect(() => {
    if (paypalLoaded && window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "39.00" } }],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
    }
    // Clean up PayPal buttons on component unmount xxFix

    return () => {
      //document.getElementById("paypal-container-YWPEQ6APSEK7N").innerHTML = "";
    };
  }, [paypalLoaded]);

  return (
    <div>
      <div className=" daily-package">
        <div className="package-card">
    <h1 className="package-title package-button2"> Pase del Día 🍃</h1>

    <p className="package-description">
      Sácale el máximo provecho al día con este paquete completo que incluye la experiencia en el cañón más 
      <strong>un delicioso almuerzo y acceso total</strong> a las instalaciones.
    </p>
  <hr></hr> <br></br>
    <ul className="package-benefits  ">
    {[
      {
        label: "Tour completo:",
        text: "Sendero, miradores y Cascada Zafiro.",
      },
      {
        label: "Almuerzo incluido:",
        text: "Casado típico con bebida natural.",
      },
      {
        label: "Tiempo libre:",
        text: "Relájate en áreas sociales y zonas verdes.",
      },
      {
        label: "Instalaciones completas:",
        text: "Vestidores, ranchitos, baños y fogón.",
      },
    ].map((item, index) => (
      <li key={index} className="  ">
        <div>
      
        🔘<strong className="text-white">
            {item.label}
          </strong> &nbsp;
        <br></br>
          <span className="text-gray-200">{item.text}</span>
        </div>
      </li>
    ))}
  </ul>



    <div className="package-price">
      <span className="price-label"> Precio:</span>
      <span className="price-amount">$39 / ₡19,990</span>
    </div>

    <p className="package-availability">
      Disponible solo sábados y domingos. <br />
      Reserva con mínimo 1 día de antelación.
    </p>

    <button className="package-button"> Reservar Ahora</button>
  </div>

      </div>
    </div>
  );
};

export default Daily;
